export default [
    {"icon": "🏖️", "keywords": "playa, vacaciones, finde, relax, mar"},
    {"icon": "🥰", "keywords": "amor, novia, novio, cita"},
    {"icon": "🗻", "keywords": "montaña, caminata, trekking, aventura, vacaciones"},
    {"icon": "🙈", "keywords": "sin titulo, no se, prueba, secreta"},
    {"icon": "❤️", "keywords": "amor, bebe, novio, novia, cita, enamorado"},
    {"icon": "🍿", "keywords": "pelicula, peli, pelis, netflix, cine, snack, pochoclos"},
    {"icon": "🍻", "keywords": "cerveza, birra,birra, bar, amigos, asado, futbol, pibes, salida, finde"},
    {"icon": "🍼", "keywords": "bebé, niño, infancia, cuidado"},
    {"icon": "🍓", "keywords": "fruta, fresa, saludable, postre, dulce"},
    {"icon": "🧉", "keywords": "yerba mate, compras, lista, plaza, plazita, parque, finde, bizcochitos"},
    {"icon": "🚗", "keywords": "auto, coche, viaje, pibes, salida"},
    {"icon": "✈️", "keywords": "avion, viaje, vuelo, aeropuerto, vacaciones"},
    {"icon": "⛺", "keywords": "campamento, acampar, naturaleza, aventura, al aire libre, vacaciones, camping, pibes, amigos"},
    {"icon": "🎬", "keywords": "pelicula, peli, pelis, netflix, cine, snack, pochoclos"},
    {"icon": "🍆", "keywords": "berenjena, saludable, compras, boliche, fiesta, noche"},
    {"icon": "🎮", "keywords": "videojuego, vicio, play, fifa, consola, diversión"},
    {"icon": "🍔", "keywords": "hamburguesa, comida rápida, almuerzo, cena, restaurante"},
    {"icon": "🍕", "keywords": "pizza, comida rápida, almuerzo, cena, restaurante"},
    {"icon": "🥐", "keywords": "factura, medialuna, desayuno, panadería, croissant, tarde, merienda,cafe,cafesito,cafecito,plaza"},
    {"icon": "🍪", "keywords": "galleta, dulce, postre, tarde, chocolate, merienda"},
    {"icon": "⚽", "keywords": "futbol, deporte, fulvo, pelota, birra, asado, fernet, partido, partidito, pibes"},
    {"icon": "🕺", "keywords": "baile, fiesta, noche, salida, boliche, pibas, musica, recital"},
    {"icon": "💃", "keywords": "baile, fiesta, noche, salida, boliche, pibas, musica, recital"},
    {"icon": "🥩", "keywords": "asado, parrilla, comida, futbol, birra, fernet"},
    {"icon": "👋", "keywords": "amigos,salida,tarde,desayuno,merienda"}
]